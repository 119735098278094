.brand-logo {
  width: 180px;
  height: 180px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  -webkit-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
  &:hover {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

.brand-logo.wbp {
  background-image: url('../../images/brands/wbp.svg');
}

.brand-logo.intothewind {
  background-image: url('../../images/brands/47-into-the-wind.svg');
}

.brand-logo.ww {
  background-image: url('../../images/brands/ww.svg');
}

.brand-logo.randr {
  background-image: url('../../images/brands/randr.svg');
}

.brand-logo.tandco {
  background-image: url('../../images/brands/tandco.jpg');
}
