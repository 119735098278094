.m-0 {
  margin: 0px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.p-0 {
  padding: 0px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.m-5 {
  margin: 5px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.p-5 {
  padding: 5px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.p-10 {
  padding: 10px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.p-15 {
  padding: 15px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.p-20 {
  padding: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.m-30 {
  margin: 30px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.p-30 {
  padding: 30px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.m-40 {
  margin: 40px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.p-40 {
  padding: 40px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.m-50 {
  margin: 50px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.p-50 {
  padding: 50px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.m-60 {
  margin: 60px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.p-60 {
  padding: 60px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.m-80 {
  margin: 80px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.p-80 {
  padding: 80px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.m-100 {
  margin: 100px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.ml-100 {
  margin-left: 100px !important;
}

.mr-100 {
  margin-right: 100px !important;
}

.p-100 {
  padding: 100px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.pl-100 {
  padding-left: 100px !important;
}

.pr-100 {
  padding-right: 100px !important;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Zen Old Mincho", serif !important;
  font-size: 15px;
}

p {
  font-size: 15px;
  line-height: 23px;
}

* {
  font-family: "Zen Old Mincho", serif !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

strong {
  font-weight: 500 !important;
}

a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: underline;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

.container-fluid {
  width: 100%;
}

.bg-light {
  background-color: #fafafa;
}

.bg-white {
  background-color: white;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute !important;
}

.z-1 {
  z-index: 1;
}

.logo {
  max-width: 1000px;
  height: 400px;
  background-image: url("../../images/tyrian.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.text-center {
  text-align: center !important;
}

.text-strong {
  font-weight: 900;
}

.cursor-pointer {
  cursor: pointer;
}

.bp-mat-dialog-container .mat-dialog-container {
  border-radius: 0px !important;
  padding: 0px !important;
  background: transparent;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  overflow: inherit !important;
}

.brand-logo {
  width: 180px;
  height: 180px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  -webkit-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}
.brand-logo:hover {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
}

.brand-logo.wbp {
  background-image: url("../../images/brands/wbp.svg");
}

.brand-logo.intothewind {
  background-image: url("../../images/brands/47-into-the-wind.svg");
}

.brand-logo.ww {
  background-image: url("../../images/brands/ww.svg");
}

.brand-logo.randr {
  background-image: url("../../images/brands/randr.svg");
}

.brand-logo.tandco {
  background-image: url("../../images/brands/tandco.jpg");
}