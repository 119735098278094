html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Zen Old Mincho', serif !important;
  font-size: 15px;
  // font-weight: 300;
}

p {
  font-size: 15px;
  line-height: 23px;
  // font-weight: 300;
}

* {
  font-family: 'Zen Old Mincho', serif !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

strong {
  font-weight: 500 !important;
}

a {
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

.container-fluid {
  width: 100%;
}

.bg-light {
  background-color: $lightGrey;
}

.bg-white {
  background-color: white;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute !important;
}

.z-1 {
  z-index: 1;
}

.logo {
  max-width: 1000px;
  height: 400px;
  background-image: url('../../images/tyrian.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.text-center {
  text-align: center !important;
}

.text-strong {
  font-weight: 900;
}

.cursor-pointer {
  cursor: pointer;
}

// * Override Mat Dialog
.bp-mat-dialog-container .mat-dialog-container {
  border-radius: 0px !important;
  padding: 0px !important;
  background: transparent;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  overflow: inherit !important;
}
