$mediumGrey: #eeeeee;
$lightGrey: #fafafa;

// * Margin and Padding
$spaceAmounts: (0, 5, 10, 15, 20, 30, 40, 50, 60, 80, 100);

@each $space in $spaceAmounts {
  .m-#{$space} {
    margin: #{$space}px !important;
  }

  .mt-#{$space} {
    margin-top: #{$space}px !important;
  }

  .mb-#{$space} {
    margin-bottom: #{$space}px !important;
  }

  .ml-#{$space} {
    margin-left: #{$space}px !important;
  }

  .mr-#{$space} {
    margin-right: #{$space}px !important;
  }

  .p-#{$space} {
    padding: #{$space}px !important;
  }

  .pt-#{$space} {
    padding-top: #{$space}px !important;
  }

  .pb-#{$space} {
    padding-bottom: #{$space}px !important;
  }

  .pl-#{$space} {
    padding-left: #{$space}px !important;
  }

  .pr-#{$space} {
    padding-right: #{$space}px !important;
  }
}
